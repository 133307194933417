import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface BusinessProfileStore {
  id: number;
  display_name: string;
}

interface BusinessProfileState {
  businessProfile: BusinessProfileStore
}

const initialState: BusinessProfileState = {
  businessProfile: {
    id: (localStorage.getItem("businessProfileId") ?
      Number(localStorage.getItem("businessProfileId")) :
      0),
    display_name: localStorage.getItem("businessProfileCachedDisplayName") || "n/a"
  }
}

const businessProfileSlice = createSlice({
  name: 'businessProfile',
  initialState,
  reducers: {
    setBusinessProfile(state, action: PayloadAction<BusinessProfileStore>) {
      state.businessProfile = action.payload;
    }
  }
});

export const { setBusinessProfile } = businessProfileSlice.actions;

export default businessProfileSlice.reducer;