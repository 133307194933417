import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect, useRef } from "react";
import { Billing } from "@/api/billing";
import { EditBillModal } from "../modals/bill/editBillModal";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { loadHandbooks } from "@/utils/loadHandbooks";

export function PaymentChip({ 
  displayAmount, 
  paymentId, 
  plannedAmount, 
  fallbackCallback = () => {}, 
  openCallback = () => {} 
}: { 
  displayAmount: string, 
  paymentId: number, 
  plannedAmount: number, 
  rentedPrice?: number,
  fallbackCallback?: () => void, 
  openCallback?: () => void 
}) {
  const [item, setItem] = useState<Billing | null>(null);
  const disclosure = useDisclosure();
  const items = useAppSelector(state => state.handbooks.payments);

  const prevIsOpenRef = useRef(disclosure.isOpen);

  useEffect(() => {
    setItem(items.find(el => el.id === paymentId) || null);
  }, [paymentId, items]);

  useEffect(() => {
    if (prevIsOpenRef.current && !disclosure.isOpen) {
      openCallback();
      loadHandbooks({ handbooks: ["billings"] });
    }

    prevIsOpenRef.current = disclosure.isOpen;
  }, [disclosure.isOpen, openCallback]);

  return (
    <>
      <EditBillModal disclosure={disclosure} billing={item} />
      {item !== null && (
        <Chip
          className="cursor-pointer"
          
          onClick={disclosure.onOpen}
          variant="dot"
          color={
            item.paid ?
              +displayAmount !== plannedAmount ? 
                'warning' : 'success'
              : 'default'
          }
        >
          {displayAmount}
        </Chip>
      )}
      {item === null && (
        <Chip 
          variant="dot" 
          classNames={{
            dot: "bg-zinc-900 dark:bg-zinc-800"
          }}
          className="cursor-pointer" 
          onClick={fallbackCallback}
        >
          {displayAmount}
        </Chip>
      )}
    </>
  );
};
