import { Column, ActionsColumn, ColumnType } from "../app/components/tables/tableTypes";

export function getNormalizedTime(date: string): string {
  return new Date(date).toLocaleString().slice(12, 17)
}

export function getNormalizedDate(date: string): string {
  return new Date(date).toLocaleString().replace(/\//g, ".").slice(0, -3)
}

export function getNormalizedDateTime(date: string): string {
  return new Date(date).toLocaleString().replace(/\//g, ".").slice(0, -3)
}

export function getPortal() {
  return document.getElementById("main")!
}

export function isActionsColumn(column: Column): column is ActionsColumn {
  return column.type === ColumnType.Actions;
}

export function hasAllPermissions(scopePermissions: string[], userPermissions: string[]) {
  return scopePermissions.every(permission => userPermissions.includes(permission));
}

export function getEnvironment(): "production" | "development" {
  return (window.location.hostname.indexOf("dev") !== -1 || window.location.hostname.indexOf("localhost") !== -1) ? "development" : "production";
}

export function getAPIPort(): number {
  return getEnvironment() == "development" ? 8200 : 8100;
}

export function getBaseUrl(): string {
  return getEnvironment() == "development" ? "https://dev.api.elasticspace.app" : "https://api.elasticspace.app";
}

export function getCurrentMonth() {
  return new Date().getMonth();
}

export function getMonthInRussian(month: number) {
  const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ];

  return months[month];
}

export function getMonthInEnglish(month: number) {
  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];

  return months[month];
}

export function getRussianMonthFromEnglish(month: string) {
  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];

  return getMonthInRussian(months.indexOf(month));
}

export function getMonthNumber(month: string) {
  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];

  return months.indexOf(month);
}

export const formatNumber = (num?: number) => (num ?? 0).toFixed(2);

export const summaryValue = (value: number | undefined) =>
  (value ?? 0).toLocaleString("ru", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export function sortAlphabeticallyByKey(array: any[], key: string) {
  return array.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
}