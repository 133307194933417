import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OfficeLocation } from "@/api/locations";
import { Button, useDisclosure } from "@nextui-org/react";
import { api } from "@/api";
import { toast } from "react-toastify";
import { FaPencil, FaElevator, FaSquare, FaRubleSign, FaDoorClosed } from "react-icons/fa6";
import { Office } from "@/api/offices";
import { EditOfficeWithLocationModal } from "@/app/components/modals/office/editOfficeWithLocaitonModal.tsx";
import useErrorHandling from "@/hooks/useErrorHandling";

export function OfficePage() {
  const { id, officeId } = useParams() as { id: string, officeId: string };
  const [location, setLocation] = useState<OfficeLocation | null>(null);
  const [office, setOffice] = useState<Office | null>(null);

  const handleError = useErrorHandling();

  const editOfficeModalDisclosure = useDisclosure();

  useEffect(() => {
    api.locations.get(Number(id))
      .then(response => {
        setLocation(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });

    api.offices.get(Number(officeId))
      .then(response => {
        setOffice(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    id,
    setLocation,
    editOfficeModalDisclosure.isOpen
  ]);

  return (
    <div>
      <EditOfficeWithLocationModal disclosure={editOfficeModalDisclosure} office={office} location={location} />
      {location && office && (
        <div className="flex flex-col gap-2 p-2 md:p-4">
          <div className="flex flex-col gap-2 mb-2">
            <span className="text-2xl font-bold">{office.display_name}</span>
            <div className="flex flex-row gap-2 items-center">
              <FaSquare />
              <span className="text-sm">Площадь: {office.area}</span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaRubleSign />
              <span className="text-sm">Цена: {office.price}</span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaElevator />
              <span className="text-sm">Этаж: {office.floor}</span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaDoorClosed />
              <span className="text-sm">Количество комнат: {office.room_count}</span>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaDoorClosed />
              <span className="text-sm">Тип офиса: {office.office_type}</span>
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <Button startContent={<FaPencil />} color="primary" variant="solid" className="max-w-fit" onClick={() => editOfficeModalDisclosure.onOpen()}>Редактировать</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}