import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { Lock } from "@/api/locks";

export function LockChip({ lockId }: { lockId: number }) {
  const [item, setItem] = useState<Lock | null>(null);
  const items = useAppSelector(state => state.handbooks.lock);
  const disclosure = useDisclosure();

  useEffect(() => {
    setItem(items.find(el => el.id == lockId) || null);
  }, [lockId, items]);

  return (
    <>
      {item !== null && (
        <Chip className="cursor-pointer" onClick={disclosure.onOpen} variant="dot" color="primary">{item?.display_name}</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">н/д</Chip>
      )}
    </>
  );
};