import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/reducer";
import businessProfileReducer from "./businessProfile/reducer";
import handbooksReducer from "./handbooks/reducer";
import notificationReducer from "./notifications/reducer";

const rootStore = configureStore({
  reducer: {
    user: userReducer,
    businessProfile: businessProfileReducer,
    handbooks: handbooksReducer,
    notifications: notificationReducer
  }
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
export default rootStore;