import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect, useRef } from "react";
import { Office } from "@/api/offices";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { EditOfficeModal } from "../modals/office/editOfficeModal";
import { loadHandbooks } from "@/utils/loadHandbooks";

export function OfficeChip({ officeId, displayName, openCallback = () => {} }: { officeId: number, displayName?: string, openCallback?: () => void }) {
  const [item, setItem] = useState<Office | null>(null);
  const disclosure = useDisclosure();
  const items = useAppSelector(state => state.handbooks.office);

  const prevIsOpenRef = useRef(disclosure.isOpen);

  useEffect(() => {
    setItem(items.find(el => el.id == officeId) || null);
  }, [officeId, items]);

  useEffect(() => {
    if (prevIsOpenRef.current && !disclosure.isOpen) {
      openCallback();
      loadHandbooks({ handbooks: ["offices"] });
    }

    prevIsOpenRef.current = disclosure.isOpen;
  }, [disclosure.isOpen, openCallback]);

  return (
    <>
      <EditOfficeModal disclosure={disclosure} office={item} />
      {item !== null && (
        <Chip className="cursor-pointer" onClick={disclosure.onOpen} variant="dot" color="primary">{displayName || item?.display_name}</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">н/д</Chip>
      )}
    </>
  );
};