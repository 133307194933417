import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface OfficeType {
  id: number;
  display_name: string;
}

export interface OfficeTypeCreate {
  display_name: string;
}

export interface OfficeTypeUpdate {
  display_name: string;
}

export function OfficeTypesAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/office-types/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<OfficeType[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/office-types/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<OfficeType>;
    },
    create: async (officeTypeData: OfficeTypeCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/office-types/?business_profile_id=${businessProfileId}`, officeTypeData, true) as ApiResponse<OfficeType>;
    },
    update: async (id: number, officeTypeData: OfficeTypeUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/office-types/${id}?business_profile_id=${businessProfileId}`, officeTypeData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/office-types/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
