import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  DatePicker,
} from "@nextui-org/react";
import { RegisterOptions, useForm, Controller } from "react-hook-form";
import { handbookMap, InputType } from "./builderTypes";
import { parseDate } from "@internationalized/date";
import { ConfirmDeleteModal } from "./service/confirmDeleteModal";
import { ConfirmEditModal } from "./service/confirmEditModal";
import { I18nProvider } from "@react-aria/i18n";

export interface InputField {
  type: InputType;
  name: string;
  label: string;
  placeholder: string;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  isRequired?: boolean;
}

export interface DefaultValues {
  [key: string]: any;
}

export interface ModalError {
  field: string;
  message: string;
}

export interface ModalBuilderProps {
  title: string;
  fields: InputField[];
  defaultValues?: DefaultValues;
  isOpen: boolean;
  isSensitiveData: boolean;
  submitButtonText?: string;
  deleteButtonText?: string;
  modalErrors?: ModalError[];
  onSubmit: (data: any) => Promise<Boolean>;
  onDelete?: () => Promise<Boolean>;
  onOpenChange: (open: boolean) => void;
}

export function ModalBuilder({
  title,
  fields,
  defaultValues,
  isOpen,
  isSensitiveData,
  submitButtonText = "Создать",
  deleteButtonText = "Удалить",
  modalErrors = [],
  onSubmit,
  onDelete,
  onOpenChange,
}: ModalBuilderProps) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<DefaultValues>({ defaultValues });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DefaultValues | null>(null);

  const editSensitiveDisclosure = useDisclosure();
  const deleteSensitiveDisclosure = useDisclosure();

  useEffect(() => {
    if (modalErrors.length) {
      modalErrors.forEach(({ field, message }) => {
        setError(field, { type: "manual", message });
      });
    } else {
      clearErrors();
    }
  }, [modalErrors, setError, clearErrors]);

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [defaultValues, setValue]);

  const registerOptions = useMemo<Record<string, RegisterOptions>>(() => {
    const options: Record<string, RegisterOptions> = {};
    fields.forEach((field) => {
      if (field.isRequired) {
        options[field.name] = { required: "Это поле обязательно для заполнения" };
      }
    });
    return options;
  }, [fields]);

  const handleSubmitSensitive = useCallback(
    async (formData: DefaultValues) => {
      setLoading(true);
      try {
        await onSubmit(formData);
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  const handleSensitiveDelete = useCallback(async () => {
    if (onDelete) {
      setLoading(true);
      try {
        await onDelete();
      } finally {
        setLoading(false);
      }
    }
  }, [onDelete]);

  const handleFormSubmit = useCallback(
    (formData: DefaultValues) => {
      if (isSensitiveData) {
        setData(formData);
        editSensitiveDisclosure.onOpen();
      } else {
        handleSubmitSensitive(formData);
      }
    },
    [isSensitiveData, editSensitiveDisclosure, handleSubmitSensitive]
  );

  const handleDelete = useCallback(() => {
    if (isSensitiveData) {
      deleteSensitiveDisclosure.onOpen();
    } else {
      handleSensitiveDelete();
    }
  }, [isSensitiveData, deleteSensitiveDisclosure, handleSensitiveDelete]);

  const getHandbookComponent = (type: InputType) => {
    return handbookMap[type];
  };

  const renderInput = useCallback(
    (field: InputField) => {
      switch (field.type) {
        case "text":
        case "email":
        case "datetime-local":
        case "password":
          return <Controller
            control={control}
            name={field.name}
            rules={registerOptions[field.name]}
            render={({ field: controllerField }) => (
              <Input
                variant="bordered"
                placeholder={field.placeholder}
                label={field.label}
                isRequired={field.isRequired}
                isInvalid={!!errors[field.name]}
                type={field.type}
                errorMessage={errors[field.name]?.message?.toString()}
                value={controllerField.value}
                onValueChange={controllerField.onChange}
              />
            )}
          />;

        case "boolean":
          return (
            <Controller
              control={control}
              name={field.name}
              render={({ field: controllerField }) => (
                <Checkbox
                  {...controllerField}
                  isSelected={controllerField.value}
                  onChange={controllerField.onChange}
                  isInvalid={!!errors[field.name]}
                >
                  {field.label}
                </Checkbox>
              )}
            />
          );

        case "datepicker":
          return (
            <Controller
              control={control}
              name={field.name}
              render={({ field: controllerField }) => (
                <I18nProvider locale="ru-RU">
                  <DatePicker
                    label={field.label}
                    variant="bordered"
                    isRequired={field.isRequired}
                    onChange={(date) => controllerField.onChange(date.toString())}
                    value={controllerField.value ? parseDate(controllerField.value) : undefined}
                    isInvalid={!!errors[field.name]}
                    errorMessage={errors[field.name]?.message?.toString()}
                  />
                </I18nProvider>
              )}
            />
          );

        default:
          const HandbookComponent = getHandbookComponent(field.type);
          if (HandbookComponent) {
            return (
              <Controller
                control={control}
                name={field.name}
                render={({ field: controllerField }) => (
                  <HandbookComponent
                    isInvalid={!!errors[field.name]}
                    errorMessage={errors[field.name]?.message?.toString()}
                    onSelectionChange={(key: React.Key) => controllerField.onChange(key)}
                    selectedKey={String(controllerField.value)}
                  />
                )}
              />
            );
          }
          return null;
      }
    },
    [control, errors, register, registerOptions, defaultValues]
  );

  return (
    <>
      <ConfirmEditModal
        isOpen={editSensitiveDisclosure.isOpen}
        onOpenChange={editSensitiveDisclosure.onOpenChange}
        callback={handleSubmitSensitive}
        data={data}
      />
      
      <ConfirmDeleteModal
        isOpen={deleteSensitiveDisclosure.isOpen}
        onOpenChange={deleteSensitiveDisclosure.onOpenChange}
        callback={handleSensitiveDelete}
      />

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        portalContainer={document.getElementById("main")!}
        backdrop="blur"
        scrollBehavior="outside"
        isDismissable={false}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>{title}</ModalHeader>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <ModalBody>
                  {fields.map((field) => (
                    <div key={field.name} className="mb-2 block">
                      {renderInput(field)}
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-grow flex-row gap-2">
                    <Button color="default" onClick={onClose} variant="flat">
                      Закрыть
                    </Button>
                  </div>
                  {onDelete && (
                    <Button color="danger" onClick={handleDelete} variant="flat">
                      {deleteButtonText}
                    </Button>
                  )}
                  <Button color="success" type="submit" variant="flat" isLoading={loading}>
                    {submitButtonText}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
