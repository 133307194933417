import { Link } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "@/api";
import { toast } from "react-toastify";
import { TableBuilder } from "@/app/components/tables/tableBuilder.tsx";
import { RentedOffice } from "@/api/rentedOffices";
import { ColumnType } from "@/app/components/tables/tableTypes.ts";

export function RenterOfficesPage() {
  const { id } = useParams();
  const [rentedOffices, setRentedOffices] = useState<RentedOffice[]>([]);

  useEffect(() => {
    api.rentedOffices.list()
      .then(response => {
        setRentedOffices(response.data.filter(rs => rs.renter == Number(id)));
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке арендованных офисов!");
      });
  }, [id]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link href={`/dashboard/renters/${id}`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Информация о арендаторе
          </Link>
          <Link href={`/dashboard/renters/${id}/offices`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Офисы в аренде
          </Link>
          <Link href={`/dashboard/renters/${id}/employees`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Сотрудники арендатора
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-1 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row w-full gap-4 items-center">
                <span className="font-medium">
                  Офисы в аренде
                </span>
              </div>
            </div>
            <TableBuilder
              displayOptions={false}
              displaySearch={false}
              removeWrapper={true}
              columns={[
                {
                  label: "Офис",
                  key: "office",
                  type: ColumnType.Office
                },
                {
                  label: "Цена",
                  key: "price",
                  type: ColumnType.Number
                },
                {
                  label: "Дата начала",
                  key: "start_date",
                  type: ColumnType.Date
                },
                {
                  label: "Дата окончания",
                  key: "end_date",
                  type: ColumnType.Date
                }
              ]}
              data={rentedOffices}
              rowsPerPage={100}
              defaultSortDescriptor={{
                column: "office",
                direction: "ascending"
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
