import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Checkbox,
  DatePicker,
  DateValue,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { useState, useEffect } from "react";
import { FaPercent, FaPlus, FaRubleSign } from "react-icons/fa6";
import { NewRenterHandbook } from "../../handbooks/renterHandbook";
import { useForm, Controller } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import {
  getIdFromOfficeHandbook,
  getIdFromRenterHandbook,
  getIdFromRequisitesHandbook,
} from "@/utils/getIdFromHandbooks.ts";
import { NewRequisitesHandbook } from "../../handbooks/requisitesHandbook";
import { NewOfficeHandbook } from "../../handbooks/officeHandbook";
import { I18nProvider } from "@react-aria/i18n";

const months = [
  { key: "Январь", label: "Январь" },
  { key: "Февраль", label: "Февраль" },
  { key: "Март", label: "Март" },
  { key: "Апрель", label: "Апрель" },
  { key: "Май", label: "Май" },
  { key: "Июнь", label: "Июнь" },
  { key: "Июль", label: "Июль" },
  { key: "Август", label: "Август" },
  { key: "Сентябрь", label: "Сентябрь" },
  { key: "Октябрь", label: "Октябрь" },
  { key: "Ноябрь", label: "Ноябрь" },
  { key: "Декабрь", label: "Декабрь" },
];

interface CreateBillForm {
  number: string;
  date: DateValue;
  payer: number;
  provider: number;
  office: number;
  discount: number;
  period: string;
  amount: number;
  paid: boolean;
  description?: string;
}

export function CreateBillModal({
  disclosure,
}: {
  disclosure: ReturnType<typeof useDisclosure>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [officePrice, setOfficePrice] = useState<number | null>(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<CreateBillForm>({
    defaultValues: {
      amount: 0,
      number: "0",
      discount: 0,
      paid: false
    }
  });
  const offices = useAppSelector((state) => state.handbooks.office);
  const handleError = useErrorHandling();

  const watchAmount = watch("amount", 0);
  const watchDiscount = watch("discount", 0);

  const calculateFinalAmount = (amount: number, discount: number) => {
    const discountAmount = (amount * discount) / 100;
    const finalSum = amount - discountAmount;
    return finalSum > 0 ? finalSum : 0;
  };

  useEffect(() => {
    setFinalAmount(calculateFinalAmount(watchAmount, watchDiscount));
  }, [watchAmount, watchDiscount]);

  const onHandle = async (data: CreateBillForm) => {
    setLoading(true);
    console.log(data);

    const payer = await getIdFromRenterHandbook(String(data.payer));
    const provider = await getIdFromRequisitesHandbook(String(data.provider));
    const office = await getIdFromOfficeHandbook(String(data.office));

    api.billings
      .create({
        amount: data.amount,
        date: `${data.date.year}-${data.date.month}-${data.date.day}`,
        description: data.description || "",
        discount: data.discount || 0,
        number: String(data.number),
        paid: data.paid || false,
        payer: Number(payer),
        period: data.period,
        provider: Number(provider),
        office: Number(office),
      })
      .then((_data) => {
        toast.success("Счёт успешно создан!");
        disclosure.onClose();
      })
      .catch((err) => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  };

  const onOfficeChange = (office: number) => {
    const foundOffice = offices.find((o) => o.id == office);
    console.log(foundOffice);
    if (foundOffice) {
      setOfficePrice(foundOffice.price);
    }
  };

  useEffect(() => {
    reset();
    setOfficePrice(null);
    setFinalAmount(0);
    setLoading(false);
  }, [disclosure.isOpen, reset]);

  return (
    <>
      <Modal
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.onOpenChange}
        portalContainer={document.getElementById("main")!}
        backdrop="blur"
        scrollBehavior="outside"
        isDismissable={false}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Создание счёта</ModalHeader>
              <form onSubmit={handleSubmit(onHandle)}>
                <ModalBody className="flex flex-col gap-4">
                  <div className="flex flex-row gap-2">
                    <Controller
                      name="number"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          label="Счёт"
                          placeholder="Номер счёта с бухгалтерии"
                          isRequired
                          isInvalid={!!errors["number"]}
                          errorMessage={errors["number"]?.message?.toString() || ""}
                          variant="bordered"
                          value={(field.value ?? 0).toString()}
                          onValueChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <I18nProvider locale="ru-RU">
                          <DatePicker
                            label="Дата платежа"
                            variant="bordered"
                            isRequired
                            onChange={field.onChange}
                            isInvalid={!!errors["date"]}
                            errorMessage={
                              errors["date"]?.message?.toString() || ""
                            }
                          />
                        </I18nProvider>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="period"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          label="Оплата за месяц"
                          placeholder="Выберите месяц"
                          variant="bordered"
                          isRequired
                          isInvalid={!!errors["period"]}
                        >
                          {months.map((month) => (
                            <AutocompleteItem
                              key={month.key}
                              onClick={() => field.onChange(month.key)}
                            >
                              {month.label}
                            </AutocompleteItem>
                          ))}
                        </Autocomplete>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="provider"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <NewRequisitesHandbook
                          isInvalid={!!errors["provider"]}
                          errorMessage={
                            errors["provider"]?.message?.toString() || ""
                          }
                          selectedKey={field.value}
                          onSelectionChange={(key) => field.onChange(key)}
                          children={<></>}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="office"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <NewOfficeHandbook
                          isInvalid={!!errors["office"]}
                          errorMessage={
                            errors["office"]?.message?.toString() || ""
                          }
                          selectedKey={field.value}
                          onSelectionChange={(key) => {
                            field.onChange(key);
                            onOfficeChange(Number(key));
                          }}
                          children={<></>}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="payer"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <NewRenterHandbook
                          isInvalid={!!errors["payer"]}
                          errorMessage={
                            errors["payer"]?.message?.toString() || ""
                          }
                          selectedKey={field.value}
                          onSelectionChange={(key) => {
                            field.onChange(key);
                          }}
                          children={<></>}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <Controller
                      name="amount"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          className="w-[60%]"
                          label="Сумма"
                          placeholder="Сумма без учёта скидки"
                          isRequired
                          isInvalid={!!errors["amount"]}
                          errorMessage={
                            errors["amount"]?.message?.toString() || ""
                          }
                          variant="bordered"
                          value={(field.value ?? 0).toString()}
                          onValueChange={field.onChange}
                        />
                      )}
                    />
                    <div className="flex flex-row justify-center items-center w-[40%]">
                      {officePrice ? (
                        <span className="cursor-pointer" onClick={_e => setValue("amount", officePrice)}>{officePrice.toLocaleString()} ₽</span>
                      ) : (
                        <span className="text-foreground text-opacity-30 text-sm">
                          Выберите офис
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <Controller
                      name="discount"
                      control={control}
                      render={({ field }) => (
                        <Input
                          label="Скидка"
                          placeholder="Скидка, в процентах"
                          endContent={<FaPercent />}
                          isInvalid={!!errors["discount"]}
                          errorMessage={
                            errors["discount"]?.message?.toString() || ""
                          }
                          variant="bordered"
                          value={(field.value ?? "0").toString()}
                          onValueChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <Input
                          label="Описание"
                          placeholder="Описание к счёту"
                          isInvalid={!!errors["description"]}
                          errorMessage={
                            errors["description"]?.message?.toString() || ""
                          }
                          variant="bordered"
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <Controller
                      name="paid"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          isSelected={field.value}
                          onChange={field.onChange}
                        >
                          Оплачено
                        </Checkbox>
                      )}
                    />
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <span className="text-foreground text-opacity-50 font-medium">
                      Итоговая сумма:
                    </span>
                    <div className="flex flex-row items-center gap-1">
                      <span className="font-bold text-lg">
                        {finalAmount.toLocaleString()}
                      </span>
                      <FaRubleSign className="text-lg" />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-grow flex-row gap-2">
                    <Button color="default" onClick={onClose} variant="flat">
                      Закрыть
                    </Button>
                  </div>
                  <Button
                    color="success"
                    type="submit"
                    variant="flat"
                    startContent={<FaPlus />}
                    isLoading={loading}
                  >
                    Создать
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
