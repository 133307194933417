import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect, useRef, useCallback } from "react";
import { EditRentedOfficeModal } from "../modals/office/editRentedOfficeModal";
import { RentedOffice } from "@/api/rentedOffices";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { loadHandbooks } from "@/utils/loadHandbooks";

export function RentedOfficeChip({ 
  displayName, 
  rentedOfficeId, 
  fallbackCallback = () => {}, 
  openCallback = () => {} 
}: { 
  displayName: string, 
  rentedOfficeId: number, 
  fallbackCallback?: () => void, 
  openCallback?: () => void 
}) {
  const [item, setItem] = useState<RentedOffice | null>(null);
  const disclosure = useDisclosure();
  const items = useAppSelector(state => state.handbooks.rentedOffices);

  useEffect(() => {
    setItem(items.find(el => el.id === rentedOfficeId) || null);
  }, [rentedOfficeId, items]);

  const prevIsOpenRef = useRef(disclosure.isOpen);

  useEffect(() => {
    if (prevIsOpenRef.current && !disclosure.isOpen) {
      openCallback();
      loadHandbooks({ handbooks: ["rentedOffices"] });
    }

    prevIsOpenRef.current = disclosure.isOpen;
  }, [disclosure.isOpen, openCallback]);

  const onClick = useCallback(() => {
    disclosure.onOpen();
  }, [disclosure]);

  return (
    <>
      <EditRentedOfficeModal disclosure={disclosure} office={item} />
      
      {item !== null ? (
        <Chip 
          className="cursor-pointer" 
          onClick={onClick} 
          variant="dot" 
          color="primary"
        >
          { displayName }
        </Chip>
      ) : (
        <Chip 
          variant="dot" 
          color="default" 
          className="cursor-pointer" 
          onClick={fallbackCallback}
        >
          { displayName }
        </Chip>
      )}
    </>
  );
};
