import { Skeleton } from "@nextui-org/react";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Admin } from "@/api/auth/auth.ts";
import { api } from "@/api";
import { toast } from "react-toastify";

export function UserProfilePage() {
  const { id } = useParams();

  const [user, setUser] = useState<Partial<Admin> | null>(null);
  const bussinessProfile = useAppSelector(state => state.businessProfile.businessProfile);

  useEffect(() => {
    api.administrators.getAdminById(Number(id))
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {
        toast.error("Произошла ошибка при загрузке данных администратора")
        console.error(err);
      });
  }, [id]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row gap-4 items-center">
                <span className="font-medium">
                  {user != null ? (
                    <span className="font-medium">{user?.username}</span>
                  ) : (
                    <Skeleton className="rounded-md">Имя Фамилия</Skeleton>
                  )}
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-row gap-4 items-center">
                <Skeleton className="w-16 h-16 rounded-full" />
              </div>
              <div className="flex flex-col gap-1">
                {user != null ? (
                  <span className="font-medium">{user?.first_name} {user?.last_name}</span>
                ) : (
                  <Skeleton className="rounded-md">Имя Фамилия</Skeleton>
                )}
                {user != null ? (
                  <span className="text-foreground-600">Администратор в {bussinessProfile.display_name}</span>
                ) : (
                  <Skeleton className="rounded-md">Администратор там-то там-то</Skeleton>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Номер телефона</span>
                {user != null ? (
                  <span className="font-medium">{user?.phone_number}</span>
                ) : (
                  <Skeleton className="rounded-md">+8 (800) 555-35-35</Skeleton>
                )}
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Адрес электронной почты</span>
                {user != null ? (
                  <span className="font-medium">{user?.email}</span>
                ) : (
                  <Skeleton className="rounded-md">example@dog.cat</Skeleton>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center mb-3">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">О себе</span>
                {user != null ? (
                  <span className="font-medium">Пока что тут будет пусто :)</span>
                ) : (
                  <Skeleton className="rounded-md">Беееееебрааааа</Skeleton>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row gap-4 items-center">
                <span className="font-medium">Связи</span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Администратор</span>
                {user != null ? (
                  <span className="font-medium">{bussinessProfile.display_name}</span>
                ) : (
                  <Skeleton className="rounded-md">Администратор там-то там-то</Skeleton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
