import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface Billing {
  id: number;
  number: string;
  date: string;
  payer: number;
  provider: number;
  amount: number;
  discount: number;
  period: string;
  paid: boolean;
  description: string;
  office: number;
}

export interface BillingCreate {
  number: string;
  date: string;
  payer: number;
  provider: number;
  discount: number;
  period: string;
  amount: number;
  paid: boolean;
  description: string;
  office: number;
}

export interface BillingUpdate {
  number: string;
  date: string;
  payer: number;
  provider: number;
  discount: number;
  period: string;
  amount: number;
  paid: boolean;
  description: string;
  office: number;
}

export function BillingsAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/billings/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Billing[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/billings/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Billing>;
    },
    create: async (billingData: BillingCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/billings/?business_profile_id=${businessProfileId}`, billingData, true) as ApiResponse<Billing>;
    },
    update: async (id: number, billingData: BillingUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/billings/${id}?business_profile_id=${businessProfileId}`, billingData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/billings/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
