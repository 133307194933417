import { Button, Tab, Tabs, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { api } from "@/api";
import { Ticket } from "@/api/tickets/tickets.ts";
import { toast } from "react-toastify";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { CreateTicketModal } from "@/app/components/modals/ticket/createTicketModal";
import { FaArrowRight, FaPencil, FaPlus } from "react-icons/fa6";
import { EditTicketModal } from "@/app/components/modals/ticket/editTicketModal";
import { useNavigate } from "react-router-dom";
import useErrorHandling from "@/hooks/useErrorHandling";
import { ColumnType } from "@/app/components/tables/tableTypes";

export function TicketsPage() {
  const [tickets, setTickets] = useState<Ticket[] | null>(null);
  const [rawTickets, setRawTickets] = useState<Ticket[] | null>(null);
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [activeTab, setActiveTab] = useState<any>("open");
  const navigate = useNavigate();

  const handleError = useErrorHandling();

  const createTicketModalDisclosure = useDisclosure();
  const editTicketModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editTicket = (ticket: Ticket) => {
    setTicket(ticket);
    editTicketModalDisclosure.onOpen();
  }

  useEffect(() => {
    setTickets(rawTickets?.filter((ticket) => {
      if (activeTab == "all") return true;
      if (activeTab == "open") return ticket.status == "open";
      if (activeTab == "closed") return ticket.status == "closed";
    }) || []);
  }, [activeTab, rawTickets]);

  useEffect(() => {
    api.tickets.list()
      .then(response => {
        setRawTickets(response.data);
      })
      .catch(err => {
        toast.error("Произошла ошибке при загрузке тикетов!");
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createTicketModalDisclosure.isOpen,
    editTicketModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateTicketModal disclosure={createTicketModalDisclosure} />
      <EditTicketModal disclosure={editTicketModalDisclosure} ticket={ticket} />

      <div className="flex flex-col p-4 md:p-4">
        <div className="flex flex-row justify-between w-full items-center mb-2">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Тикеты</span>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button startContent={<FaPlus />} color="primary" variant="solid" className="max-w-fit" onClick={() => createTicketModalDisclosure.onOpen()}>Новый тикет</Button>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <span className="text-sm max-w-96">
            Ниже размещены обращения в поддержку коворкинга от Ваших арендаторов.
          </span>
        </div>
        <div className="flex flex-col mb-4">
          <Tabs color="primary" variant="bordered" selectedKey={activeTab} onSelectionChange={setActiveTab}>
            <Tab key="all" title="Все обращения" />
            <Tab key="open" title="Открытые" />
            <Tab key="closed" title="Закрытые" />
          </Tabs>
        </div>
        <div className="flex flex-col mb-2 gap-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[{
              key: "title",
              label: "Тема обращения",
              type: ColumnType.String,
              sortable: true
            }, {
              key: "status",
              label: "Статус",
              type: ColumnType.Custom,
              sortable: true,
              render(value, _row) {
                return (<span>{value == "open" ? 'Открыт' : 'Закрыт'}</span>)
              },
            }, {
              key: "category",
              label: "Категория",
              type: ColumnType.Category,
              sortable: true
            }, {
              key: "office",
              label: "Офис",
              type: ColumnType.Office
            }, {
              key: "created_at",
              label: "Создан",
              type: ColumnType.DateTime,
              sortable: true
            }, {
              key: "updated_at",
              label: "Обновлён",
              type: ColumnType.DateTime,
              sortable: true
            }, {
              key: "actions",
              label: "Действия",
              type: ColumnType.Actions,
              actions: [
                { icon: <FaArrowRight />, onClick: (_value, row) => { navigate(`/dashboard/tickets/${row.id}`) } },
                { icon: <FaPencil />, onClick: (_value, row) => { editTicket(row) } }
              ]
            }]}
            data={tickets || []}
            defaultSortDescriptor={{
              column: "title",
              direction: "ascending"
            }}
          />
        </div>
      </div>
    </>
  )
}