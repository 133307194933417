import rootStore from "@/app/store";
import { handbookMap, Handbook } from "./handbookMap";
import { Dispatch } from "redux";

type LoadHandbooksOptions = {
  handbooks: Handbook[] | "all";
  dispatch?: Dispatch;
};

export async function loadHandbooks({
  handbooks
}: LoadHandbooksOptions): Promise<Record<Handbook, any>> {
  const toLoad: Handbook[] =
    handbooks === "all" ? (Object.keys(handbookMap) as Handbook[]) : handbooks;

  const promises = toLoad.map((handbook) => handbookMap[handbook].apiCall());

  const results = await Promise.allSettled(promises);

  const data: Partial<Record<Handbook, any>> = {};

  results.forEach((result, index) => {
    const handbook = toLoad[index];
    if (result.status === "fulfilled") {
      data[handbook] = result.value.data;
      
      rootStore.dispatch(handbookMap[handbook].action(result.value.data));
    } else {
      console.error(`Failed to load handbook "${handbook}":`, result.reason);
    }
  });

  return data as Record<Handbook, any>;
}