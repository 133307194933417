import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCheck, FaCopy, FaTimes } from "react-icons/fa";
import { Column, ColumnType } from "./tableTypes";
import rootStore from "../../store";
import { getNormalizedDate, getNormalizedDateTime, hasAllPermissions, isActionsColumn } from "@/utils";

import { UserChip } from "../chips/userChip";
import { RenterChip } from "../chips/renterChip";
import { OfficeTypeChip } from "../chips/officeTypeChip";
import { LocationChip } from "../chips/locationChip";
import { LockSystemChip } from "../chips/lockSystemChip";
import { OfficeChip } from "../chips/officeChip";
import { RoleChip } from "../chips/roleChip";
import { CategoryChip } from "../chips/categoryChip";
import { LockChip } from "../chips/lockChip";
import { RequisiteChip } from "../chips/requisiteChip";
import { Button } from "@nextui-org/react";

export function CopyableElement({ children }: { children: any }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      {children}
      {String(children).trim() !== "" && (
        <CopyToClipboard text={children}>
          <FaCopy className="text-foreground-400 hover:text-foreground-500 cursor-pointer transition-all" />
        </CopyToClipboard>
      )}
    </div>
  );
}

export function ActionsElement({
  actions,
  value,
  row,
}: {
  actions: any[];
  value: any;
  row: any;
}) {
  return (
    <div className="flex flex-row gap-2 items-center w-full">
      {actions.map((action, index) => (
        <Button key={index} size="sm" variant="flat" onClick={() => action.onClick(value, row)}>
          {action.icon}
        </Button>
      ))}
    </div>
  );
}

export function renderCell(row: any, column: Column) {
  const userPermissions = rootStore.getState().user.permissions;
  const data =
    column.permissions && !hasAllPermissions(column.permissions, userPermissions)
      ? "н/д"
      : row[column.key];

  switch (column.type) {
    case ColumnType.Date:
      return <CopyableElement>{getNormalizedDate(data)}</CopyableElement>;

    case ColumnType.DateTime:
      return <CopyableElement>{getNormalizedDateTime(data)}</CopyableElement>;

    case ColumnType.Number:
      return <CopyableElement>{data.toString()}</CopyableElement>;

    case ColumnType.Boolean:
      return data ?
        <FaCheck className="text-success" /> :
        <FaTimes className="text-foreground-700" />;

    case ColumnType.String:
      return <CopyableElement>{data}</CopyableElement>;

    case ColumnType.User:
      return <UserChip userId={data} />;

    case ColumnType.Renter:
      return <RenterChip renterId={data} />;

    case ColumnType.OfficeType:
      return <OfficeTypeChip officeTypeId={data} />;

    case ColumnType.Location:
      return <LocationChip locationId={data} />;

    case ColumnType.LockSystem:
      return <LockSystemChip lockSystemId={data} />;

    case ColumnType.Office:
      return <OfficeChip officeId={data} />;

    case ColumnType.UserRole:
      return <RoleChip roleId={data} />;

    case ColumnType.Category:
      return <CategoryChip categoryId={data} />;

    case ColumnType.Lock:
      return <LockChip lockId={data} />;

    case ColumnType.Requisite:
      return <RequisiteChip requisiteId={data} />;

    case ColumnType.Actions:
      if (isActionsColumn(column)) {
        return (
          <ActionsElement
            //@ts-ignore
            actions={column.actions}
            row={row}
            value={row[column.key]}
          />
        );
      }
      return "н/д";

    case ColumnType.Custom:
      return column.render ? column.render(row[column.key], row) : "н/д";

    default:
      return "н/д";
  }
}
