import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { OfficeType } from "@/api/officeTypes";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";

interface IOfficeTypeForm {
  display_name: string;
}

const editOfficeTypeFields: InputField[] = [
  {
    label: "Название",
    name: "display_name",
    placeholder: "Опен-спейс",
    type: "text",
    isRequired: true
  }
]

export function EditOfficeTypeModal({ disclosure, officeType }: { disclosure: ReturnType<typeof useDisclosure>, officeType: OfficeType | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IOfficeTypeForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.officeTypes.update(officeType!.id, {
        display_name: data.display_name
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.officeTypes.delete(officeType!.id)
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        })
    });
  };

  return (
    <ModalBuilder
      title="Редактирование типа офиса"
      fields={editOfficeTypeFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: officeType?.display_name
      }} />
  );
}