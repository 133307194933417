import { Card, CardBody, Tab, Tabs } from "@nextui-org/react";
import { UserRolePage } from "./userRoles";
import { OfficeTypesPage } from "./officeTypes";
import { LockSystemsPage } from "./lockSystems";

export function SystemParamsPage() {
  return (
    <>
      <div className="p-4">
        <div className="flex flex-col gap-2 mb-2">
          <div className="flex flex-row gap-4 items-center mb-2">
            <span className="font-bold text-2xl">Системные параметры</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <Tabs aria-label="Вкладки">
            <Tab key="roles" title="Роли пользователей">
              <Card>
                <CardBody>
                  <UserRolePage />
                </CardBody>
              </Card>
            </Tab>
            <Tab key="officeTypes" title="Типы офисов">
              <Card>
                <CardBody>
                  <OfficeTypesPage />
                </CardBody>
              </Card>
            </Tab>
            <Tab key="lockSystems" title="Системы замков">
              <Card>
                <CardBody>
                  <LockSystemsPage />
                </CardBody>
              </Card>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}