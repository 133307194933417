import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { LockSystem } from "@/api/lockSystems/lockSystems.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";

interface IEditLockSystemForm {
  api_key: string;
}

const editLockSystemFields: InputField[] = [
  {
    label: "Ключ API",
    name: "api_key",
    placeholder: "0xFFFFFF",
    type: "text",
    isRequired: true
  }
]

export function EditLockSystemModal({ disclosure, lockSystem }: { disclosure: ReturnType<typeof useDisclosure>, lockSystem: LockSystem | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IEditLockSystemForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.lockSystems.update(lockSystem!.id, {
        api_key: data.api_key
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  return (
    <ModalBuilder
      title="Редактирование замка"
      fields={editLockSystemFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        api_key: lockSystem?.api_key
      }} />
  );
}